<template>
  <div>
    <Breadcrumb :items="breadcrumb" />
    <v-container class="py-10">
      <ProductDetail
        v-if="product"
        :product="product"
        :selectedImage="selectedImage"
        :key="'product_detail_' + key"
        @selectImage="selectImage"
      />
      <not-found v-else />
    </v-container>
    <v-container class="px-md-3">
      <ProductDescription
        v-if="product"
        :productDescription="product.metaData?.product_description?.DESCRIPTION"
        :key="'product_description_' + product.productId + '_key_' + key"
        :ingredientList="product.ingredientList"
      />
      <!-- <ProductDescription
        v-if="product"
        :productDescription="productDescription"
        :key="'product_description_' + product.productId + '_key_' + key"
        :ingredientList="product.ingredientList"
      /> -->
    </v-container>

    <template v-if="product && product.productId">
      <!-- default layout 220 -> Altri clienti hanno acquistato: -->
      <category-block
        :target="category"
        :productId="product.productId"
        :categoryId="product.categoryId"
        :key="product.productId"
        position="position4"
        class="category-block category-block-1"
      ></category-block>

      <!-- default: crm layout 223 -> Ti potrebbe interessare... -->
      <category-block
        :target="category"
        :productId="product.productId"
        :categoryId="product.categoryId"
        :key="product.productId + '--1'"
        position="position5"
        class="category-block category-block-2"
      ></category-block>
    </template>
  </div>
</template>
<style lang="scss">
.product-detail-view {
  img.normal {
    height: 360px;
    width: 360px;
  }
  img.image-mobile {
    height: 336px;
    width: 336px;
  }
  .thumb-wrapper {
    img {
      width: 80px;
      height: 80px;
    }
  }
  .back-button {
    &:hover {
      opacity: 0.5;
    }
  }
  .description-column {
    min-height: 296px;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      min-height: 504px;
    }
  }
}
</style>
<script>
import join from "lodash/join";
import compact from "lodash/compact";
import get from "lodash/get";

import ProductDescription from "@/components/product/ProductDescription.vue";
import ProductDetail from "@/components/product/ProductDetail.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
// import ProductListSlider from "@/components/product/ProductListSlider.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import categoryMixin from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";
import NotFound from "@/views/NotFound.vue";

export default {
  name: "Product",
  mixins: [categoryMixin, deliveryReactive],
  jsonld() {
    if (this.product.productId) {
      let images = this.product.media.map(item => item.medium);
      return {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: this.product.name,
        image: images,
        description: this.product.description,
        sku: this.product.codInt,
        mpn: this.product.codInt,
        brand: {
          "@type": "Brand",
          name: this.product.shortDescr
        }
        // aggregateRating: {
        //   reviewCount: this.product.ratingSummary.total,
        //   ratingValue: this.product.ratingSummary.avg
        //   // bestRating: // ex: 5,0
        // },
        // offers: {
        //   "@type": "Offer",
        //   url: process.env.VUE_APP_EBSN_URL + "/" + this.product.slug,
        //   priceCurrency: "EUR",
        //   price: this.product.price ? this.product.price : 0,
        //   priceValidUntil: "2050-12-31", // NON HO UNA DATA VALIDA DI PREZZO
        //   itemCondition: "https://schema.org/NewCondition",
        //   availability:
        //     this.product.available >= 0
        //       ? "https://schema.org/InStock"
        //       : "https://schema.org/OutOfStock"
        // }
      };
    }
  },
  data() {
    return {
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      showNoProductCard: false,
      selectedImage: null,
      key: 1,
      layout20length: 0
    };
  },
  components: {
    ProductDescription,
    ProductDetail,
    Breadcrumb,
    NotFound
  },
  computed: {
    getFullName() {
      return join(
        compact([
          this.product.name,
          this.product.shortDescr,
          this.product.description
        ]),
        " "
      );
    },
    categoryName() {
      return get(this.category, "name");
    }
  },
  methods: {
    async getProduct() {
      var data = await ProductService.getProductBySlug(this.slug);
      this.setProduct(data);
      this.product = data;
      if (this.product.categoryId) {
        this.setCategory(this.product.categoryId);
      }

      if (this.product && this.product.media && this.product.media.length) {
        this.selectImage(this.product.media[0]);
      }
    },
    async setProduct(product) {
      // var data = await ProductService.getProductBySlug(this.slug);
      if (product) {
        this.product = product;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }
        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
        analyticsService.viewProductDetail(this.product);
      } else {
        this.product = null;
      }
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      //this.$store.dispatch("category/resetFilters");
      //let slug = split(this.category.slug, "@")[0];
      //await this.setCategory(slug);
      await this.getProduct();
      this.key += 1;
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  async mounted() {
    this.slug = this.$route.params.slug;
    this.setProduct(this.$route.params.product);
    // this.showNoProductCard = false;
    // await this.getProduct();
    // analyticsService.viewProductDetail(this.product);
  },
  watch: {
    async $route(to) {
      if (to.params.slug != this.slug) {
        this.slug = to.params.slug;
        this.setProduct(this.$route.params.product);

        // await this.getProduct();
        // analyticsService.viewProductDetail(this.product);
      }
    }
  },
  metaInfo() {
    if (this.product)
      return {
        title: this.$route.meta.gtm,
        link: [
          {
            rel: "canonical",
            href: `https://${window.location.host}/product/${this.product.slug}`
          }
        ],
        meta: [
          {
            vmid: "description",
            name: "description",
            content: get(
              this.product,
              "metaData.product_seo.SEO_DESCRIPTION",
              this.getFullName +
                " online. " +
                this.categoryName +
                " in offerta e promozione su Coop Trentino con consegna in azienda e ritiro in negozio."
            )
          },
          // Open Graph Metas
          {
            vmid: "og:title",
            property: "og:title",
            content: get(
              this.product,
              "metaData.product_seo.SEO_TITLE",
              this.getFullName
            )
          },
          {
            vmid: "og:type",
            property: "og:type",
            content: "product"
          },
          {
            vmid: "og:url",
            property: "og:url",
            content: location.href
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: get(
              this.product,
              "media[0].large",
              window.location.host + global.config.socialLogo ||
                "/logo/social-logo.png"
            )
          },
          {
            vmid: "og:image:alt",
            property: "og:image:alt",
            content: get(
              this.product,
              "metaData.product_seo.SEO_DESCRIPTION",
              this.getFullName +
                " online. " +
                this.categoryName +
                " in offerta e promozione su Coop Trentino con consegna in azienda e ritiro in negozio."
            )
          },
          {
            vmid: "og:site_name",
            property: "og:site_name",
            content: "CoopTrentino"
          },
          // Twitter Metas
          {
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            name: "twitter:title",
            content: get(
              this.product,
              "metaData.product_seo.SEO_DESCRIPTION",
              this.getFullName +
                " online. " +
                this.categoryName +
                " in offerta e promozione su Coop Trentino con consegna in azienda e ritiro in negozio."
            )
          },
          {
            name: "twitter:image",
            content: get(
              this.product,
              "media[0].large",
              window.location.host + global.config.socialLogo ||
                "/logo/social-logo.png"
            )
          },
          {
            name: "twitter:url",
            content: location.href
          }
        ]
      };
  }
};
</script>
